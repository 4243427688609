import ContentCard from "../wrapers/ContentCard";
import classes from "./ImAuftrag.module.css";

function ImAuftrag(props) {
  return (
    <ContentCard className={classes.container} title="Eine Diplomarbeit der">
      <figure className={classes.logocontainer}>
          <img src="/resources/logos/htl3r.png" alt=""/>
          <figcaption><a href="https://htlrennweg.at" className="dark">HTL-Rennweg</a></figcaption>
      </figure>
    </ContentCard>
  );
}

export default ImAuftrag;
