import classes from "./ContentCard.module.css";

function ContentCard(props) {
  console.log("Hello", props.className);

  return (
    <div className={`${classes.contentcard} ${props.className}`}>
      <p className={classes.title}>
        {props.title}
      </p>
      {props.children}
    </div>
  );
}

export default ContentCard;
