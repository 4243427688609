import classes from "./HeaderBar.module.css";
import {forwardRef} from "react";
import IconMail from "../resources/icons/IconMail";
import LogoGithub from "../resources/logos/LogoGithub";
import LogoInstagram from "../resources/logos/LogoInstagram";

const HeaderBar = forwardRef((props, ref) => {
    return (
        <div className={classes.headerbar} ref={ref}>
            <p className={classes.logo}>NETADMIN</p>
            <a href="mailto:office@netadmin.at" className={classes.iconcont}>
                <IconMail className={classes.icon}/>
            </a>
            <a href="https://github.com/htl3r-netadmin" className={classes.iconcont} target="_blank" rel="noreferrer">
                <LogoGithub className={classes.icon}/>
            </a>
            <a href="https://instagram.com/netadmin.at" className={classes.iconcont} target="_blank" rel="noreferrer">
                <LogoInstagram className={classes.icon}/>
            </a>
        </div>
    );
})

export default HeaderBar;
