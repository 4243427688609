import TitlePage from "./content/TitlePage";
import HeaderBar from "./components/HeaderBar";
import DieIdee from "./content/DieIdee";
import DasTeam from "./content/DasTeam";
import DieZiele from "./content/DieZiele";
import FooterBar from "./components/FooterBar";
import Kontakt from "./content/Kontakt";
import ImAuftrag from "./content/ImAuftrag";
import { useRef } from "react";

function App() {
  let header = useRef(null);
  function scrollToContent () {
    header.current.scrollIntoView({behavior: "smooth", align: "start"});
  }

  return (
    <div className="App">
      <TitlePage onArrowClick={scrollToContent}/>
      <HeaderBar ref={header} />
      <main className="main">
        <DieIdee />
        <DieZiele />
        <DasTeam />
        <Kontakt />
        <ImAuftrag />
      </main>
      <FooterBar />
    </div>
  );
}

export default App;
