import classes from "./TitlePage.module.css";
import LogoNetadmin from "../resources/logos/LogoNetadmin";
import IconArrowDown from "../resources/icons/IconArrowDown";

function TitlePage(props) {
    return (
        <div className={classes.titlepage}>
            <LogoNetadmin className={classes.logo}/>
            <p className={classes.description}>
                Eine Software zur Überwachung und Verwaltung von Netzwerken.
            </p>
            <IconArrowDown className={classes.down} onClick={props.onArrowClick}/>
        </div>
    );
}

export default TitlePage;
