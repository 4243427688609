import Ziel from "../components/Ziel";
import ContentCard from "../wrapers/ContentCard";
import {useState} from "react";
import classes from "./DieZiele.module.css";

function DieZiele(props) {
    let ziele = [
        {
            ziel: "1. Zentrale Überwachung",
            description:
                "Das erste Ziel ist es, einen Service zu bauen, welcher vom Administrator angegebene Netzwerk-Komponenten überwachen kann. Dabei wird auf die Netzwerkkomponenten per Telnet oder SSH zugegriffen und definierte Konfigurationen werden ausgelesen. NetAdmin ist auf Cisco Router und Cisco Switches zugeschnitten.",
        },
        {
            ziel: "2. Zentrale Steuerung",
            description:
                "NetAdmin bietet auch die Möglichkeit, die Geräte zu steuern. Es können einfache Befehle an einzelne Geräte geschickt werden (wie z.B. starte dich neu, ändere die IP, etc.), aber auch selbst definierte Befehle können ausgeführt werden. Somit wird auch das Passwortchaos minimiert, weil sich NetAdmin selbst um den Login kümmert.",
        },
        {
            ziel: "3. Datenbank / Historische Daten",
            description:
                "Die im ersten Ziel gesammelten Daten werden nun in eine Datenbank gepflegt. Hierbei können Daten auch historisch gespeichert werden, sodass man auch im Nachhinein Auswertungen erstellen kann oder das Verhalten des Netzwerkes über einen längeren Zeitraum beobachten kann.",
        },
        {
            ziel: "4. Auswertung",
            description:
                "Die Daten, welche nun in der Datenbank liegen, werden ausgewertet und es wird speziell auf vorher definierte Indikatoren geschaut, die auf gewisse Unstimmigkeiten hinweisen könnten. Die Ergebnisse dieser Auswertung werden dann auch in die Datenbank gespeichert und können zu einem späteren Zeitpunkt von einem Nutzer abgefragt werden.",
        },
        {
            ziel: "5. Weboberfläche",
            description:
                "Um mit NetAdmin dann auch kommunizieren zu können, wird eine Weboberfläche erstellt. Hier werden die ausgewerteten Daten ansprechend dargestellt und Unstimmigkeiten, welche NetAdmin gefunden hat, sind einzusehen. Auf der Oberfläche kann man auch die vorher erwähnten Befehle an einzelne Geräte schicken.",
        },
    ];

    let [openDesc, setOpenDesc] = useState(new Array(ziele.length).fill(false));

    return (
        <ContentCard title="Die Ziele">
            <div className={classes.zielecontainer}>
                {ziele.map((value, index) => (
                    <Ziel
                        key={index}
                        openDesc={openDesc}
                        setOpenDesc={setOpenDesc}
                        title={value.ziel}
                    >
                        <p style={{textAlign: "justify"}}>{value.description}</p>
                    </Ziel>
                ))}
            </div>
        </ContentCard>
    );
}

export default DieZiele;
