import ContentCard from "../wrapers/ContentCard";
import classes from "./Kontakt.module.css";
import IconMail from "../resources/icons/IconMail";
import LogoGithub from "../resources/logos/LogoGithub";
import LogoInstagram from "../resources/logos/LogoInstagram";

function Kontakt() {
    return (
        <ContentCard title="Wo finden Sie uns?">
            <div className={classes.kontaktcontainer}>
                <a href="mailto:office@netadmin.at" className={classes.kontaktmgl}>
                    <IconMail className={classes.icon}/>
                    <p className={classes.short}>office@netadmin.at</p>
                    <p className={classes.long}>mailto:office@netadmin.at</p>
                </a>
                <a href="https://github.com/htl3r-netadmin" className={classes.kontaktmgl} target="_blank" rel="noreferrer">
                    <LogoGithub className={classes.icon}/>
                    <p className={classes.short}>@htl3r-netadmin</p>
                    <p className={classes.long}>https://github.com/htl3r-netadmin</p>
                </a>
                <a href="https://www.instagram.com/netadmin.at/" className={classes.kontaktmgl} target="_blank" rel="noreferrer">
                    <LogoInstagram className={classes.icon}/>
                    <p className={classes.short}>@netadmin.at</p>
                    <p className={classes.long}>https://www.instagram.com/netadmin.at/</p>
                </a>
            </div>
        </ContentCard>
    );
}

export default Kontakt;
