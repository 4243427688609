import classes from "./TeamMember.module.css";

function TeamMember(props) {
    return (
        <div className={classes.teammember}>
            <img className={classes.portrait} src={props.portrait} alt=""/>
            <p className={classes.name}>{props.name}</p>
            <p className={classes.role}>{props.role}</p>
            <p className={classes.job}>{props.job}</p>
        </div>
    );
}

export default TeamMember;