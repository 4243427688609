import TeamMember from "../components/TeamMember";
import ContentCard from "../wrapers/ContentCard";
import classes from "./DasTeam.module.css";

function DasTeam() {
  return (
    <ContentCard title="Das Team">
      <div className={classes.teamcontainer}>
        <TeamMember
          portrait="/resources/portraits/jeremiaszzrolka.webp"
          name="Jeremiasz Zrolka"
          role="Projektleiter"
          job="Management & Weboberfläche"
        />
        <TeamMember
          portrait="/resources/portraits/philippscheer.webp"
          name="Philipp Scheer"
          role="Stv. Projektleiter"
          job="Datenauswertung"
        />
        <TeamMember
          portrait="/resources/portraits/peterbezak.webp"
          name="Peter Bezak"
          role="Projektmitglied"
          job="Security & Gerätezugriff"
        />
        <TeamMember
          portrait="/resources/portraits/patrickdivos.webp"
          name="Patrick Divos"
          role="Projektmitglied"
          job="Dateninfrastruktur"
        />
      </div>
    </ContentCard>
  );
}

export default DasTeam;
