import classes from "./FooterBar.module.css";

function FooterBar(props) {
    return (<footer className={classes.footer}>
        <Content title="Impressum">
            Website der Diplomarbeit NetAdmin<br/>
            an der HTL Rennweg im Schuljahr 2021/22<br/>
            Offizieller Titel: NetAdmin - Netzwerküberwachung<br/>
            Projekt-Nummer: 3R IT 22 10
        </Content>
        <Content title="Projektleitung">
            Jeremiasz Zrolka<br/>
            E-Mail: <a href="mailto:jeremiasz.zrolka@netadmin.at">jeremiasz.zrolka@netadmin.at</a>
        </Content>
        <Content title="Schulstandort">
            HTL Rennweg<br/>
            Höhere Abteilung für Informationstechnologie<br/>
            (Ausbildungsschwerpunkt Netzwerktechnik)<br/>
            Rennweg 89b, 1030 Wien<br/>
            Website: <a href="https://www.htlrennweg.at/">www.htlrennweg.at</a><br/>
            E-Mail: <a href="mailto:direktion@htl.rennweg.at">direktion@htl.rennweg.at</a>
        </Content>
    </footer>);
}

function Content(props) {
    return [
        <p className={classes.title}>{props.title}</p>,
        <p className={classes.content}>{props.children}</p>]
}

export default FooterBar;