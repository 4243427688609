import * as React from "react";

function LogoNetadmin(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 408.52 71.87"
            {...props}
        >
            <path
                d="M75.51,47.35V71.44H116V60.49H103.84c-6.72,0-12.45-.11-12.78-.22s-.58-1-.58-4.75V51h23V41.14h-23V33.47H115.3V23.25H75.51Z"/>
            <path d="M118.88,29.27v6h15.34V71.44h15V35.3h15v-12H118.88Z"/>
            <path
                d="M240.66,47.33l.11,24.11h12.82c13.93,0,16.38-.26,20.6-2.2,4-1.8,8.24-6.89,9.64-11.47,1.54-5,1.65-14.07.18-19.68s-5.35-10.66-9.71-12.75c-3.81-1.87-5.94-2.09-20.45-2.09H240.58Zm24.62-12c2.28,1.13,3.67,3.26,4.29,6.59s.26,11.84-.51,13.6a8,8,0,0,1-3.26,3.88c-1.29.66-7.22,1.32-9,1l-1.21-.22v-26l4,.15C262.76,34.43,263.82,34.61,265.28,35.31Z"/>
            <path
                d="M287.8,47.19V71.12l.83.18a57.15,57.15,0,0,0,6.06.11L300,71.3l.18-17.37.18-17.37,4.35,17c2.36,9.32,4.53,17.19,4.82,17.48s1.64.47,5.63.4l5.14-.11,4.54-17.7,4.53-17.69.11,17.59.07,17.58,1.2.22a52.66,52.66,0,0,0,6.06.11l4.89-.11V23.43L332,23.32l-9.65-.07-.58,1.89c-.29,1.05-2,7.54-3.7,14.39-2.21,8.6-3.26,12.26-3.48,11.79s-1.78-6.49-3.59-13.56-3.44-13.24-3.63-13.68l-.32-.83H287.8Z"/>
            <path
                d="M345,23.5c-.14.11-.25,10.87-.25,23.88V71l.83.22c.44.11,3.77.22,7.43.22h6.59V23.25h-7.17A58.3,58.3,0,0,0,345,23.5Z"/>
            <path
                d="M362.84,23.4c-.14.15-.25,10.93-.25,24V71.08l1.2.22a67.71,67.71,0,0,0,7,.11l5.81-.11.18-12.85c.14-9.52.29-12.71.58-12.35s4.14,6,8.68,12.67,8.46,12.28,8.68,12.42a42,42,0,0,0,7.11.18l6.72-.07V23.37h-13.8l-.18,13.07-.18,13-8.9-13-8.89-13.07-6.76-.11A65.57,65.57,0,0,0,362.84,23.4Z"/>
            <path
                d="M197,26.42c-8.83,0-15.14,5.59-18,8.71,2.94,3.55,9.59,10.25,18,10.25,9.09,0,15.36-6.68,18-10.17C212.29,32.13,206,26.42,197,26.42Zm0,17.07a7.59,7.59,0,1,1,7.59-7.59A7.59,7.59,0,0,1,197,43.49Z"/>
            <path d="M197,32.11a3.8,3.8,0,1,0,3.8,3.79A3.79,3.79,0,0,0,197,32.11Z"/>
            <path
                d="M209.23.73C209,.41,204,.19,196.83.19H184.75L179.82,13.3c-2.71,7.26-8.72,23.23-13.32,35.58s-8.45,22.48-8.45,22.59,5.09.21,11.26.21h11.32l.65-2.33c.38-1.24,1.19-3.9,1.79-5.95l1.08-3.63,12.51.11,12.52.16L211,65.89l1.78,5.79,11.49-.11c7.25-.1,11.42-.38,11.37-.7C235.55,70.11,209.61,1.33,209.23.73ZM197,49.17c-13.61,0-22.78-14.12-22.78-14.12S182.65,22.63,197,22.63c14.59,0,22.72,12.42,22.72,12.42S211.66,49.17,197,49.17Z"/>
            <path
                d="M49.06,53.13a8.62,8.62,0,0,1,1-4.07L39.59,43a4.54,4.54,0,1,1-6.94-5.85L17,24.64A8.12,8.12,0,0,1,11.41,28L12.77,51h0A6,6,0,0,1,16,62.07l4.8,9.6h0l.27-19.17c.22-14.19.44-19,.87-18.42S28.16,43,34.93,53c2.1,3.07,4.1,6,5.87,8.54l8.86-5.23A8.71,8.71,0,0,1,49.06,53.13Z"/>
            <path
                d="M63.13,6.23A6.33,6.33,0,0,1,59.4,17.67a6.62,6.62,0,0,1-1.1-.09l-2.41,11.2-.12,0a4.81,4.81,0,0,1,3,4l9.72,0V.88Z"/>
            <path
                d="M47.76,12.18l-.1,7.49-.27,19.45L39.67,27.81l-1.81,8.13a4.57,4.57,0,0,1,2.79,4.19,4.49,4.49,0,0,1-.5,2.07l10.46,6a8.67,8.67,0,0,1,5.31-3.52L54.55,38A4.58,4.58,0,0,1,54,38a4.8,4.8,0,0,1,0-9.59,4.43,4.43,0,0,1,.9.09l2.41-11.18A6.34,6.34,0,0,1,53.1,12Z"/>
            <path d="M8,60.6a6,6,0,0,1,3.76-9.5L10.42,28A8.15,8.15,0,0,1,2.34,20.6L0,21c0,4.58,0,9.67,0,15V69.63Z"/>
            <path
                d="M68.51,33.74l-9.72,0a4.83,4.83,0,0,1-3.26,4.05l1.38,6.77.78,0a8.62,8.62,0,0,1,4.58,15.93L68.51,71V33.74Z"/>
            <path d="M53.07,11a6.36,6.36,0,0,1,2.86-5l-3-5.86h-5l-.15,11Z"/>
            <path d="M15.14,62.54a6,6,0,0,1-6.46-1.19L0,71.14v.2l1.79.33a101.2,101.2,0,0,0,10.4.16l7.53-.14Z"/>
            <path d="M56.81,5.56a6.56,6.56,0,0,1,5.45.13L67.8.18H54Z"/>
            <path
                d="M61.39,60.92a8.51,8.51,0,0,1-3.7.84,8.65,8.65,0,0,1-7.61-4.56l-8.7,5.15c3.69,5.33,6.28,9,6.5,9.16s5.09.37,10.61.27l9.26-.1Z"/>
            <path
                d="M2.32,19.6a8.13,8.13,0,0,1,10.79-7.39l7.35-12L10.78,0A95.64,95.64,0,0,0,.38.23C.23.38.1,8.42,0,20Z"/>
            <path d="M14,12.6a8.13,8.13,0,0,1,3.52,11.19L33.38,36.5a4.64,4.64,0,0,1,3.51-.84l2-9-4.78-7L21.26.78Z"/>
        </svg>
    );
}

export default LogoNetadmin;
