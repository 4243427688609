import ContentCard from "../wrapers/ContentCard";
import classes from "./DieIdee.module.css";

function DieIdee(props) {
    return (
        <ContentCard title="Was ist NetAdmin?">
            <b>Das Problem</b>
            <p className={classes.content}>
                Je größer ein Netzwerk wird, desto schwerer wird es, dieses zu verwalten.
                Mit jedem neuen Gerät steigt der Administrationsaufwand.
                An userer Schule, der <a className="dark" href="https://www.htlrennweg.at">HTL-Rennweg</a>, haben wir
                ein Netzwerk mit weit mehr als 100 Produktivgeräten (Router und Switches), die bei Maximalauslastung
                jede:n unserer 1000 Schüler:innen und 100 Lehrkräfte mit Internet versorgen müssen. Bei einer solchen
                Anzahl an Geräten wird die Verwaltung zu einer Meisteraufgabe. Alle Komponenten müssen stets
                überwacht werden und es muss sichergestellt, dass sie richtig funktionieren. Benötigte Änderungen müssen schnell
                durchgeführt werden und falls bei so einer Änderung mal etwas schiefgeht, ist die Fehlersuche auch eine
                Herausforderung für sich selbst. Jede dieser Tätigkeiten kann regelmäßig viele Stunden in Anspruch
                nehmen und ist im Regelfall eher mühselig. Genauso ist in der selben Zeit das Netzwerk nicht voll
                funktionsfähig was dazu führen kann, dass manche User:innen entweder keine oder nur sehr begrenzte
                Internetverbindungen haben.
            </p>
            <br/>

            <b>NetAdmin</b>
            <p className={classes.content}>
                Bei diesem aufwendigen Prozess soll NetAdmin nun Aushilfe schaffen.<br/>
                In erster Linie wird dem Administrator geholfen, den Überblick über sein Netzwerk zu bewahren. Der
                Administrator hat die Möglichkeit, sich die Konfiguration und den Status eines Gerätes anzuschauen ohne sich
                direkt auf dem Gerät einloggen zu müssen. Außerdem können Benachrichtigungen versendet werden, sobald ein
                vorher definiertes Ereignis eintritt.<br/>
                So können Fehlkonfigurationen von Geräten, Restkonfigurationen und
                andere Unstimmigkeiten im eigenen Netzwerk viel schneller erkannt, lokalisiert und behoben werden,
                sodass der Normalzustand wieder hergestellt ist.
            </p>
        </ContentCard>
    );
}

export default DieIdee;
