import classes from "./Ziel.module.css";
import {useRef, useState} from "react";
import {CSSTransition} from "react-transition-group";
import IconDropdown from "../resources/icons/IconDropdown";

function Ziel(props) {
    const [showDesc, setShowDesc] = useState(false);
    const [descHeight, setDescHeight] = useState(0);
    const desc = useRef(null);

    let iconClasses = {
        enter: classes["icon-enter"],
        enterDone: classes["icon-enter-done"],
        exit: classes["icon-exit"],
        exitDone: classes["icon-exit-done"],
    }

    function getDescHeight() {
        let height = 0;
        for (let i of desc.current.children) {
            height += i.offsetHeight;
        }
        return height;
    }

    return (
        <div className={classes.container}>
            <div className={classes.title} onClick={() => setShowDesc(!showDesc)}>
                <p>{props.title}</p>
                <CSSTransition
                    in={showDesc}
                    classNames={iconClasses}
                    timeout={0}
                    onEnter={() => setDescHeight(getDescHeight())}
                    onExit={() => setDescHeight(0)}
                >
                    <IconDropdown />
                </CSSTransition>
            </div>
            <div className={classes.description} ref={desc} style={{height: descHeight}}>{props.children}</div>
        </div>
    );
}

export default Ziel;
